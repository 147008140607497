import React from 'react'
import PropTypes from 'prop-types'
import DropdownMenu from 'react-dd-menu'

import DownArrowIcon from '../../../static/images/down-arrow.svg'
import 'react-dd-menu/src/scss/react-dd-menu.scss'

import './menuDropdown.scss'

class Dropdown extends React.Component {
  constructor() {
    super()
    this.state = { open: false }
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  toggle = (e) => {
    e.preventDefault()
    const { open } = this.state
    this.setState({ open: !open })
  }

  render() {
    const { children, item } = this.props
    const { open } = this.state
    const menuOptions = {
      isOpen: open,
      close: this.handleClose,
      toggle: (
        <a
          href
          onClick={this.toggle}
          className={`menu-dropdown-item ${open && 'menu-dropdown-item-opened'}`}
        >
          {item.label} <DownArrowIcon />
        </a>
      ),
    }

    return (
      <DropdownMenu {...menuOptions} className='menu-dropdown'>
        {children}
      </DropdownMenu>
    )
  }
}

Dropdown.propTypes = {
  item: PropTypes.object,
  children: PropTypes.array,
}

export default Dropdown

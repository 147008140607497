import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import LanguageSelector from '../components/menu/LanguageSelector'
import './footer.scss'
import { RichText } from '../components/html'
import CompanyDetailsContext from '../context/CompanyDetailsContext'

const Footer = ({ data, langs, location }) => {
  const { email, telephone } = React.useContext(CompanyDetailsContext)
  const {
    logo, address, links, description,
  } = data
  return (
    <div className='dark-background'>
      <div className='footer-container'>
        <div className='max-width-container side-padding'>
          <div className='footer-wrapper'>
            <div className='footer-left-column'>
              <div className='logo-container'>
                <img src={logo.url} alt='Pumas Cleaning logo' />
                {description && (
                  <div className='footer-description'>
                    <RichText render={description} />
                  </div>
                )}
              </div>
            </div>
            <div className='column-fill' />
            <div className='footer-right-column'>
              <h5>Contact Us</h5>
              <div className='links-wrapper footer-contact-details'>
                {address && <p>{address}</p>}
                <div><a href={`mailto:${email}`}>{email}</a></div>
                <div><a href={`tel:${telephone.replace(/\s/g, '')}`}>{telephone}</a></div>
              </div>
              <h5>Links</h5>
              <div className='links-wrapper'>
                {links.map(({ linkItem }) => (
                  <div key={linkItem.id}>
                    <Link to={linkItem.document.data.link}>{linkItem.document.data.label}</Link>
                  </div>
                ))}
                {langs.length > 1 && (
                  <div className='language-selector-footer'><LanguageSelector location={location} langs={langs} /></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='credits-wrapper'>
        <div><small>© Pumas Cleaning. All rights reserved.</small></div>
        <div>
          <small>Website built by <a href='http://thehype.nz' target='_blank' rel='noopener noreferrer'>The Hype - Digital Agency</a></small>
        </div>
      </div>
    </div>
  )
}

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  langs: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
}

export default Footer

import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import LangIcon from '../../../static/images/language.svg'

import { defaultLanguage } from '../../../prismic-config'
import './LanguageSelector.scss'

// TODO: select default language value based on user
const LanguageSelector = ({ langs, location }) => {
  const getLangShortName = (lang) => lang.split('-')[0]

  const handleLangChange = (e) => {
    const langSelected = e.target.value
    const langSelectedName = getLangShortName(langSelected)
    // we store the lang in the cookies to keep the user in the choosen lang on future visits

    if (typeof window !== 'undefined' && window.localStorage) {
      window.localStorage.setItem('lang', langSelected)
    }

    let newPrefix = ''
    if (langSelected !== defaultLanguage) {
      newPrefix = `/${langSelectedName}`
    }

    const currentLang = langs.find((langItem) => location.pathname.startsWith(`/${getLangShortName(langItem)}`))
    if (currentLang && currentLang !== defaultLanguage) {
      // Current lang in the pathname is not default so we replace with new lang
      const newPath = location.pathname.replace(`/${getLangShortName(currentLang)}`, newPrefix)
      navigate(newPath)
    } else {
      // previous lang is default meaning in the url we dont have a lang reference
      // so we add the new lang as prefix to the current pathname
      navigate(`${newPrefix}${location.pathname}`)
    }
  }

  return (
    <div className='lang-selector-container'>
      <LangIcon />
      <select
        className='lang-selector'
        name='lang'
        id='language-select-box'
        onChange={handleLangChange}
      >
        {langs
          .map((lang) => <option key={lang} value={lang}>{getLangShortName(lang)}</option>)
        }
      </select>
    </div>
  )
}

LanguageSelector.propTypes = {
  langs: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
}

export default LanguageSelector

import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import EmailIcon from '../../../static/images/mail-icon.svg'
import TelIcon from '../../../static/images/phone.svg'
import SmallTelIcon from '../../../static/images/small-phone.svg'
import LangContext from '../../context/LangContext'
import CompanyDetailsContex from '../../context/CompanyDetailsContext'
import './button.scss'

// when this value is used as a label in a button.
// it'll get replaced by the company number we have in Prismic in the doc: General Data
const COMPANY_TELEPHONE = '<telephone>'

const Button = ({
  children, isButton, className, secondary, icon, title, to, ...rest
}) => {
  const { langPrefix } = React.useContext(LangContext)
  const { telephone } = React.useContext(CompanyDetailsContex)

  if (children === COMPANY_TELEPHONE) {
    return (
      <a className='hype-button hype-button-secondary' href={`tel:${telephone.trim()}`}>
        {icon && <img src={icon.url} alt={icon.alt} />}
        <div className='right-column'>
          {title && <span>{title.text}</span>}
          {telephone}
        </div>
      </a>
    )
  }

  if (isButton) {
    return (
      <button type='button' className={`hype-button ${className}`} {...rest}>
        {icon && <img src={icon.url} alt={icon.alt} />}
        <div className='right-column'>
          {title && <span>{title.text}</span>}
          {children}
        </div>
      </button>)
  }
  return (
    <Link
      to={`${langPrefix}${to}`}
      className={`hype-button ${secondary && 'hype-button-secondary'} ${className}`}
      {...rest}
    >
      {icon && <img src={icon.url} alt={icon.alt} />}
      <div className='right-column'>
        {title && <span>{title.text}</span>}
        {children}
      </div>
    </Link>)
}


Button.propTypes = {
  children: PropTypes.string,
  isButton: PropTypes.bool,
  to: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  noPadding: PropTypes.bool,
}

export const query = graphql`
  fragment PrismicButtonFragment on PrismicButtons {
    data {
      button {
        title {
          text
        }
        label
        url
        style
        icon {
          fluid {
            sizes
            src
            aspectRatio
            base64
            srcWebp
            srcSetWebp
          }
          url
          alt
        }
      }
    }
  }
`

export default Button

const CallButton = ({ tel, label, type }) => (
  tel ? (
    <a
      className={`hype-button ${type === 'sm' && 'small-padding-button'}`}
      href={`tel:${tel.trim().replace(/\s/g, '')}`}
    >
      <SmallTelIcon />
      {label || tel}
    </a>
  ) : null
)
CallButton.propTypes = {
  tel: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf(['sm', 'md', 'lg']),
}

export const SmallCallButton = (props) => <CallButton {...props} type='sm' />
export const MediumCallButton = (props) => <CallButton {...props} type='md' />

const LargeButton = ({
  icon, title, type, value,
}) => (
  <a
    className={`
      hype-button hype-button-secondary hype-button-contact
      ${type === 'email' && 'hype-button-contact-email'}
    `}
    href={type === 'phone' ? `tel:${value.trim()}` : `mailto:${value}`}
  >
    {icon}
    <div className='right-column'>
      {title && <span>{title}</span>}
      {value}
    </div>
  </a>
)
LargeButton.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['tel', 'email']),
  value: PropTypes.string,
}

export const LargeCallButton = ({ tel, ...rest }) => (
  <LargeButton
    value={tel}
    title='Call Us Now'
    type='phone'
    icon={<TelIcon />}
    {...rest}
  />
)
export const LargeEmailButton = ({ email, ...rest }) => (
  <LargeButton
    value={email}
    title='Email'
    type='email'
    icon={<EmailIcon />}
    {...rest}
  />
)

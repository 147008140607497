import React from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'

import CloseIcon from '../../../static/images/close.svg'
import LangContext from '../../context/LangContext'
import NavItem from './NavItem'
import LanguageSelector from './LanguageSelector'
import MenuIcon from '../../../static/images/menu.svg'
import './menu.scss'
import { MediumCallButton, SmallCallButton } from '../button'
import CompanyDetailsContext from '../../context/CompanyDetailsContext'
import PageContext from '../../context/PageContext'


const Menu = (props) => {
  const [open, toggleMobileMenuOpen] = React.useState(false)
  const { langPrefix } = React.useContext(LangContext)
  const { telephone, email } = React.useContext(CompanyDetailsContext)

  const getLogo = () => {
    const { logo } = props
    return <a href={langPrefix || '/'}><img src={logo.url} alt={logo.alt} className='menu-logo' /></a>
  }

  const formatItemData = (data) => {
    const {
      label, link, items: innerItems,
    } = data.item.document.data
    const { id } = data.item
    let formattedInnerItems
    if (innerItems && innerItems.length > 0) {
      formattedInnerItems = innerItems.map(formatItemData)
    }
    return {
      id, label, link, innerItems: formattedInnerItems,
    }
  }

  const toggleMobileMenu = () => {
    if (!open) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
    toggleMobileMenuOpen(!open)
  }

  const getMenuItems = (isBurgerMenu) => {
    const {
      items, langs, location,
    } = props
    const menuItems = (items.map(formatItemData).map((item) => (
      <NavItem
        isBurgerMenu={isBurgerMenu}
        item={item}
        toggleMobileMenu={toggleMobileMenu}
      />
    )))
    if (langs.length > 1) {
      menuItems.push(<LanguageSelector langs={langs} location={location} />)
    }

    return menuItems
  }

  const getCallToActions = () => (
    <React.Fragment>
      {email && <a href={`mailto:${email.trim()}`}>{email.trim()}</a>}
      {telephone && (
        <MediumCallButton tel={telephone} label={telephone} />
      )}
    </React.Fragment>
  )

  const { themeName } = React.useContext(PageContext)
  if (!open) {
    return (
      <div className={`menu-display side-padding ${themeName}-theme`}>
        <Headroom style={{ transition: 'all .5s ease-in-out' }}>
          <div className='max-width-container'>
            <div className='page-container menu-container'>
              {getLogo()}
              <div className='menu-items-container'>
                {getMenuItems()}
              </div>
              <div className='menu-ctas-container'>
                {getCallToActions()}
              </div>
            </div>
            <div className='menu-burger'>
              <button
                className='menu-burger-button'
                type='button'
                onClick={toggleMobileMenu}
              >
                <MenuIcon />
              </button>
              {getLogo()}
              <SmallCallButton tel={telephone} label='Call Us' />
            </div>
          </div>
        </Headroom>
      </div>)
  }

  return (
    <div className={`menu-overlay ${themeName}-theme`}>
      <div className='overlay-header'>
        {getLogo()}
        <button href type='button' className='menu-closebtn' onClick={toggleMobileMenu}>
          <CloseIcon />
        </button>
      </div>
      <div className='menu-overlay-content'>
        {getMenuItems(true)}
      </div>
    </div>
  )
}

Menu.propTypes = {
  items: PropTypes.array,
  langs: PropTypes.array,
  location: PropTypes.object.isRequired,
  logo: PropTypes.object,
}

export default Menu

import React from 'react'
import PropTypes from 'prop-types'

const RichText = ({ render }) => {
  if (render && render.html) {
    return <div dangerouslySetInnerHTML={{ __html: render.html }} />
  }
  return null
}

RichText.propTypes = { render: PropTypes.object.isRequired }

export default RichText

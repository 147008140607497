import React from 'react'
import PropTypes from 'prop-types'

import { defaultLanguageName } from '../../prismic-config'
import LangContext from '../context/LangContext'
import OgTags from '../components/seo/tags'
import Menu from '../components/menu'
import Footer from '../layouts/footer'
import CompanyDetailsContext from '../context/CompanyDetailsContext'
import PageContext from '../context/PageContext'

const getLangName = (lang) => lang.split('-')[0]

const MasterPage = ({
  navBarData, footerData, children, ogData, location, themeName, generalData,
}) => {
  if (!navBarData) { return null } // DO NOT REMOVE THIS, BUILD WILL BREAK

  const pageLangName = getLangName(navBarData.lang)
  const [langContextValue] = React.useState({
    langPrefix: pageLangName !== defaultLanguageName ? `/${pageLangName}` : '',
  })
  const langs = [navBarData.lang].concat(navBarData.alternateLangs.map((l) => l.lang))

  const { email, telephone } = generalData
  const [companyDetails] = React.useState({
    telephone, email,
  })
  const [pageDetails] = React.useState({
    themeName,
  })

  return (
    <LangContext.Provider value={langContextValue}>
      <CompanyDetailsContext.Provider value={companyDetails}>
        <PageContext.Provider value={pageDetails}>
          <OgTags {...ogData} />
          <Menu
            langs={langs}
            items={navBarData.data.items}
            logo={navBarData.data.logo}
            location={location}
          />
          <div className='main-content'>{children}</div>
          <Footer data={footerData} langs={langs} location={location} />

          <link rel='preconnect' href='https://fonts.googleapis.com' />
          <link rel='preconnect' href='https://fonts.gstatic.com' crossOrigin />
          <link
            href='https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap'
            rel='stylesheet'
          />
        </PageContext.Provider>
      </CompanyDetailsContext.Provider>
    </LangContext.Provider>
  )
}

MasterPage.defaultProps = {
  ogData: {},
  themeName: 'light',
}

MasterPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  navBarData: PropTypes.object, // Prismic data
  footerData: PropTypes.object, // Prismic data
  ogData: PropTypes.object, // Prismic data
  location: PropTypes.object.isRequired,
  generalData: PropTypes.object.isRequired,
  themeName: PropTypes.string,
}

export default MasterPage
